import React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

function Texfield(props) {
  const { name, nombre, handleActualizarObjeto, value, error, mensajeError,disabled } =
    props;

  return (
    <div>
      <Stack spacing={3}>
        <TextField
          id="outlined-basic"
          name={name}
          label={nombre}
          onChange={handleActualizarObjeto}
          disabled={disabled}
          value={value}
          variant="outlined"
          error={error} // mostrar un mensaje de error debajo del campo de texto si el valor no es un DNI o NIE válido
          helperText={
            error ? mensajeError : ""
          }
          sx={{ marginTop:1, minWidth: 50 }}
        />
      </Stack>
    </div>
  );
}
export default Texfield;
