import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useState, useContext, useEffect } from "react";
import "../../../../App.css";
import ButtonNormal from "../../ComponentsReusable/Botons/ButtonNormal";
import ilustracionPrincipal from "../../../Resources/IlustraciónPrincipal.svg";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import altreFiltre from "../../../Resources/altreFiltre.svg";
import UploadFileComponent from "./UploafFileComponent";
import usePortalPublic from "../../../Hooks/constants/usePortalPublic";
import { tipusDeFicheros } from "./ObjetosYArrays";
import Avatar from "@mui/material/Avatar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { ToastContext, tipusToast } from "../../ComponentsReusable/Toast/Toast";
import Investigador from "./Investigador";
import GestorDeBiobancs from "./GestorDeBiobanc";

const BoxPortalPublic = () => {
  const {
    // validarCodi,
    codiValidat,
    validarCodiAndAdjuntarDocuments,
    loading,
    popUpError,
    setPopUpError,
    errorPopUp,
    validarCodiGestorDeBiobanc,
    codiValidatGestorDeBiobanc,
    setCodiValidat,
    setCodiValidatGestorDeBiobanc,
    terminated,
    setTerminated,
  } = usePortalPublic();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { nouToast } = useContext(ToastContext);
  const [opciones, setOpciones] = useState(tipusDeFicheros);
  const mostrarToast = (text, tipus) => nouToast(text, tipus);
  const [mostrarAdjuntos, setMostrarAdjuntos] = useState(false);
  const [files, setFiles] = useState([]);

  const [fichers, setFichers] = useState([]);

  const [codiValidar, setCodiValidar] = useState("");
  const [valueGroup, setValueGroup] = useState("");
  const [codiBiobanc, setCodiBiobanc] = useState("");
  const [documnetIdentificativo, setDocumentIdentificativo] = useState("");
  const [validarDocumentIdentificativo, setValidarDocumentIdentificativo] =
    useState(false);
  const [validarCampoCodi, setValidarCampoCodi] = useState(false);

  const [filesMostres, setFilesMostres] = useState([]);

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (terminated === true) {
      handleCancelar();
      setTerminated(false);
    }

    // eslint-disable-next-line
  }, [terminated]);

  const handleCancelar = () => {
    setCodiValidat(false);
    setCodiValidatGestorDeBiobanc(false);
    setDocumentIdentificativo("");
    setValueGroup("");
    setCodiValidar("");
    setFichers([]);
    setFilesMostres([]);
  };

  const carregaButtonClick = () => {
    setOpen(true);
  };

  const validarCampo = (valor, regex) => {
    // La expresión regular para validar DNI/NIE

    // Verificar si el valor coincide con DNI o NIE
    if (regex.test(valor)) {
      return true;
    } else {
      return false;
    }
  };

  const getValidarCodi = (codi) => {
    validarCodiAndAdjuntarDocuments(codi);
  };
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "1em",
    },
  });

  useEffect(() => {
    if (codiValidat === true) {
      addArrayUpload();

      setMostrarAdjuntos(true);
    }

    // eslint-disable-next-line
  }, [codiValidat]);

  const handleChangeMostrarAdjuntos = () => {
    if (valueGroup === "investigador​") {
      if (codiValidar.length > 0) {
        getValidarCodi(codiValidar);
      } else {
        mostrarToast("Cal emplenar el camp obligatòriament", tipusToast.avis);
      }
    } else {
      if (codiBiobanc.length > 0 && documnetIdentificativo.length > 0) {
        if (
          validarDocumentIdentificativo === false &&
          validarCampoCodi === false
        ) {
          validarCodiGestorDeBiobanc(
            codiBiobanc,
            documnetIdentificativo,
            filesMostres.length !== 0 ? filesMostres : null
          );
        }
      } else {
        mostrarToast(
          "Cal emplenar tots els camps obligatòriament",
          tipusToast.avis
        );
      }
    }
  };

  useEffect(() => {
    const regexCodi = /^B\.0?\d{7}$/;

    if (!validarCampo(codiBiobanc, regexCodi) && codiBiobanc.length > 0) {
      setValidarCampoCodi(true);
    } else {
      setValidarCampoCodi(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codiBiobanc]);

  useEffect(() => {
    const regexDNI = /^\d{8}[A-Z]$/;
    const regexNIE = /^[XYZ]\d{7}[A-Z]$/;

    if (
      !validarCampo(documnetIdentificativo, regexDNI) &&
      !validarCampo(documnetIdentificativo, regexNIE) &&
      documnetIdentificativo.length > 0
    ) {
      setValidarDocumentIdentificativo(true);
    } else {
      setValidarDocumentIdentificativo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documnetIdentificativo]);

  const handleRadioChange = (event) => {
    setValueGroup(event.target.value);
    setValidarDocumentIdentificativo(false)
    setValidarCampoCodi(false)
  };

  const handleClosePopUp = () => {
    setPopUpError(false);
  };

  const handleSubmitSobreEscribiendo = () => {
    const transformedData = fichers.reduce(
      (acc, doc) => {
        acc.documents.push({
          nomDocument: doc.nomDocument,
          tipusDocument: doc.tipusDocument,
        });
        return acc;
      },
      { documents: [], pass: codiValidar, rw: true }
    );

    const arrayFiles = files.map((obj) => {
      return obj.doc;
    });

    validarCodiAndAdjuntarDocuments(codiValidar, transformedData, arrayFiles);
    resetearInvestigador();

    setPopUpError(false);
  };

  const resetearInvestigador = () => {
    for (let index = 0; index < opciones.length; index++) {
      const nuevoObjetoActualizado = [...opciones];
      nuevoObjetoActualizado[index].disabled = false;
      setOpciones(nuevoObjetoActualizado);
    }

    setCodiValidat(false);
    setCodiValidatGestorDeBiobanc(false);
    setDocumentIdentificativo("");
    setValueGroup("");
    setCodiValidar("");
    setFichers([]);
    setFilesMostres([]);
    
  };

  const addArrayUpload = () => {
    if (fichers.length < 8) {
      // setFiles(files => files.push({ fichero }));
      const nouFichers = fichers;
      const lastFicher = fichers[fichers.length - 1];
      const lastId = lastFicher ? lastFicher.id : 0;
      nouFichers.push({ id: lastId + 1 });
      setFichers([...nouFichers]);

      const nouFiles = files;
      const lastFiles = files[files.length - 1];
      const lastFileId = lastFiles ? lastFiles.id : 0;
      nouFiles.push({ id: lastFileId + 1 });

      setFiles([...nouFiles]);
    }
  };

  const handleSubmit = () => {
    const transformedData = fichers.reduce(
      (acc, doc) => {
        acc.documents.push({
          nomDocument: doc.nomDocument,
          tipusDocument: doc.tipusDocument,
        });
        return acc;
      },
      { documents: [], pass: codiValidar, rw: false }
    );

    const arrayFiles = files.map((obj) => {
      return obj.doc;
    });

    const documentsValid = transformedData.documents.every(
      (doc) => doc.nomDocument !== undefined && doc.tipusDocument !== undefined
    );

    if (documentsValid === false) {
      return (
        <>
          {mostrarToast(
            "Cal emplenar tots els camps obligatòriament",
            tipusToast.avis
          )}
        </>
      );
    } else {
      validarCodiAndAdjuntarDocuments(codiValidar, transformedData, arrayFiles);
      
    }
  };

  const handleUploadFile = (id, doc) => {
    const fichersModificats = files.map((x) =>
      x.id === id ? { ...x, doc } : x
    );

    setFiles(fichersModificats);

    const actualizarFicher = fichers.map((x) =>
      x.id === id ? { ...x, nomDocument: doc.name } : x
    );

    setFichers(actualizarFicher);
  };

  const handleDeleteRow = (id) => {
    const index = files.findIndex((x) => x.id === id);
    if (index !== -1) {
      files.splice(index, 1);
      fichers.splice(index, 1);
      setFiles([...files]);
      setFichers([...fichers]);
    }
  };

  const handleCodiValidar = (event) => {
    if (valueGroup === "investigador​") {
      setCodiValidar(event.target.value);
    } else {
      if (event.target.name === "codiBiobanc") {
        setCodiBiobanc(event.target.value);
      } else if (event.target.name === "IdDocument") {
        setDocumentIdentificativo(event.target.value);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        className="boxCenter"
        bgcolor="#FFFFFF"
        boxShadow="0px 7px 8px rgba(0, 0, 0, 0.2)"
        borderRadius="4px"
        width="auto"
        p={10}
        mx={40.5}
        mt={10}
      >
        {/* {submitSuccessful === true &&
            mostrarToast("S'ha donat d'alta correctament'", tipusToast.exit)} */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-50px",
            textAlign: "center",
            marginBottom: "75px",
          }}
        >
          <h2>Benvinguts a Biobanc</h2>
          <Avatar
            alt="Remy Sharp"
            src={ilustracionPrincipal}
            sx={{ width: 80, height: 80, marginLeft: 2 }}
          />
        </div>

        <FormControl disabled={codiValidat || codiValidatGestorDeBiobanc}>
          <FormLabel id="">
            Indiqui el tipus d’usuari amb el que accedirà a l’eina:​
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={valueGroup}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="investigador​"
              control={<Radio />}
              label="Investigador​"
            />
            <FormControlLabel
              value="gestorDeBiobancs​"
              control={<Radio />}
              label="Gestor de Biobanc​"
            />
          </RadioGroup>
        </FormControl>

        <div className="column">
          {valueGroup === "investigador​" && (
            <>
              <Investigador
                codiValidat={codiValidat}
                handleCodiValidar={handleCodiValidar}
                handleChangeMostrarAdjuntos={handleChangeMostrarAdjuntos}
                popUpError={popUpError}
                errorPopUp={errorPopUp}
                fullScreen={fullScreen}
                handleClosePopUp={handleClosePopUp}
                handleSubmitSobreEscribiendo={handleSubmitSobreEscribiendo}
              />
            </>
          )}

          {valueGroup === "gestorDeBiobancs​" && (
            <>
              <GestorDeBiobancs
                codiValidatGestorDeBiobanc={codiValidatGestorDeBiobanc}
                validarDocumentIdentificativo={validarDocumentIdentificativo}
                validarCampoCodi={validarCampoCodi}
                handleCodiValidar={handleCodiValidar}
                handleChangeMostrarAdjuntos={handleChangeMostrarAdjuntos}
                CustomTooltip={CustomTooltip}
                carregaButtonClick={carregaButtonClick}
                isOpen={isOpen}
                setOpen={setOpen}
                setTerminated={setTerminated}
                filesMostres={filesMostres}
                setFilesMostres={setFilesMostres}
              />
            </>
          )}

          <div>
            {mostrarAdjuntos === true && (
              <>
                {fichers.map((file) => {
                  return (
                    <UploadFileComponent
                      key={file.id}
                      id={file.id}
                      length={files.length}
                      handleDeleteRow={handleDeleteRow}
                      uploadFile={handleUploadFile}
                      opciones={opciones}
                      setOpciones={setOpciones}
                      fichers={fichers}
                      setFichers={setFichers}
                      // handleChangeOpciones={handleChangeOpciones}
                    />
                  );
                })}
                {fichers.length !== 7 && fichers.length > 0 && (
                  <IconButton onClick={addArrayUpload}>
                    <img src={altreFiltre} alt="altreFiltre" />
                  </IconButton>
                )}

                {fichers.length > 0 && (
                  <div style={{ float: "right", marginTop: 35 }}>
                    <ButtonNormal
                      click={handleCancelar}
                      nombre="Cancel·lar"
                      outlined={true}
                    />
                    <ButtonNormal
                      click={handleSubmit}
                      nombre="Enviar"
                      outlined={false}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Box>

      <div>
        <img
          src={ilustracionPrincipal}
          className="App-principalImage"
          alt="ilustracionPrincipal"
          width="450"
          height="300"
          style={{ display: "block", margin: "0 auto" }}
        />
      </div>
    </>
  );
};

export default BoxPortalPublic;
