export const UploadFileButtonStyle = {
  width: "max-content",
  height: "55px",
  textTransform: "none",
  borderWidth: 1.5,
  
};

export const LabelStyle = {
  marginRight: "-14px",
};

export const CancelIconStyle = {
  color: "#900000",
  backgroundColor: "white",
  padding: "10px",
};
