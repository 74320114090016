import IconButton from "@mui/material/IconButton";
import React from "react";
import "../../../../App.css";
import borrar from "../../../Resources/borrar.svg";
import Select from "../../ComponentsReusable/Select";
import UploadFileButton from "../../ComponentsReusable/UploadFileButton/UploadFileButton";
import { tipusDeFicheros } from "./ObjetosYArrays";

const UploadFileComponent = (props) => {
  const {
    length,
    handleDeleteRow,
    id,
    uploadFile,
    opciones,
    setOpciones,
    fichers,
    setFichers,
  } = props;

  const [desactivarAnterior, setDesactivarAnterior] = React.useState();

  const handleMostrar = () => {
    handleDeleteRow(id);
    activarDesactivarSelect(false, desactivarAnterior);
  };

  const handleChangeOpciones = (event) => {
    activarDesactivarSelect(false, desactivarAnterior);

    const selectedOption = tipusDeFicheros.find(
      (option) => option.id === event.target.value
    ).id;

    const nuevoObjetoActualizado = fichers.map((x) =>
      x.id === id ? { ...x, tipusDocument: selectedOption } : x
    );
    setFichers(nuevoObjetoActualizado);

    activarDesactivarSelect(true, event.target.value);
    setDesactivarAnterior(event.target.value);
  };

  const activarDesactivarSelect = (boolean, id) => {
    if (id !== 5) {
      for (let index = 0; index < opciones.length; index++) {
        if (opciones[index].id === id) {
          const nuevoObjetoActualizado = [...opciones];
          nuevoObjetoActualizado[index].disabled = boolean;
          setOpciones(nuevoObjetoActualizado);
        }
      }
    }
  };

  return (
    <>
      <div
        className="enLinea"
        style={{ marginBottom: 20, marginTop: 20, marginLeft: 10 }}
      >
        <UploadFileButton
          label="Arxiu"
          id={id}
          deleteFile={handleDeleteRow}
          uploadFile={(doc) => uploadFile(id, doc)}
        />
        <Select
          name="tipusdearxiu"
          elements={opciones}
          nameLabel="Tipus d'arxiu"
          handleChange={handleChangeOpciones}
        />
        {length !== 1 && (
          <IconButton
            id={id + "Boton"}
            style={{ marginLeft: 18 }}
            onClick={handleMostrar}
          >
            <img src={borrar} alt="borrar" />
          </IconButton>
        )}
      </div>
    </>
  );
};

export default UploadFileComponent;
