import React from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useCallback } from "react";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";

export default function UploadFile(props) {
  const { onDrop } = props;
 
  const onDropFitxers = useCallback((acceptedFiles) => {
    onDrop(acceptedFiles);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: false,
    noKeyboard: true,
    isDragActive: true,
    onDrop: onDropFitxers,
  });
  
  const styleDropzone = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: "red",
    backgroundColor: "#F5F5F5",
    outline: "dashed #900000",
    fontFamily: "Arial",
    fontSize: 15,
    color: "#A0A0A0",
  };
  return (
    <div className="container">
      <div
        style={styleDropzone}
        onClick={open}
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />
        <CloudUploadRoundedIcon fontSize="large" sx={{ color: "#900000" }} />
        <p>Arrossega els teus fitxers a aquesta àrea (.xlsx o .xls)</p>
        <Button
          size={"small"}
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            marginTop: 3,
            color: "#900000",
            backgroundColor: "white",
            ":hover": { backgroundColor: "white" },
          }}
        >
          Seleccionar fitxers
        </Button>
      </div>
      <br></br>
    </div>
  );
}
