import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#900000",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#900000",
      light: "#900000",
      dark: "#900000",
    },
    secondary: {
      main: "#900000",
    },
    neutral: {
      main: "#900000",
    },
  },
});
