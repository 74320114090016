import "./App.css";
import React from "react";
import Header from "./Biob/Components/Header";
import AppRouter from "./Biob/Route/AppRouter";
import { BrowserRouter } from "react-router-dom";
import { theme } from "./AppStyles";
import { ThemeProvider } from "@mui/material";
import Toast from "./Biob/Components/ComponentsReusable/Toast/Toast";
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Toast>
        <BrowserRouter>
          <Header></Header>
          <AppRouter></AppRouter>
        </BrowserRouter>
        </Toast>
      </ThemeProvider>
    </div>
  );
}

export default App;
