export const urlEntorn = () => {
    let urlFront = window.location.href;

    if (urlFront.includes("localhost"))
        return "http://localhost:8090";
    else if (urlFront.includes("preproduccio"))
        return "https://preproduccio.ctti.apim.extranet.gencat.cat/ctti/public-pre";
    else
    return "https://ctti.apim.extranet.gencat.cat/ctti/public";
}

export const apimId = () => {
    let urlFront = window.location.href;

    if (urlFront.includes("localhost"))
        return "8a2f4a8855444c54ba470c44d1be8284";
    else if (urlFront.includes("preproduccio"))
        return "8a2f4a8855444c54ba470c44d1be8284";
    else
    return "dd5e866aa5ee4c87519e69f7db91a6d5";
}

export const biobanc = () => {
    let urlFront = window.location.href;

    if (urlFront.includes("localhost"))
        return urlEntorn()+"/2200-publicfrontalbiobank-pre/biobanc-pre";
    else if (urlFront.includes("preproduccio"))
        return urlEntorn()+"/2200-publicfrontalbiobank-pre/biobanc-pre";
    else
        return urlEntorn()+"/2200-publicfrontalbiobank-pro/biobanc-pro";
}

export const investigador = () => {
    let urlFront = window.location.href;

    if (urlFront.includes("localhost"))
        return urlEntorn()+"/2200-publicfrontalinvestigator-pre/biobanc-pre";
    else if (urlFront.includes("preproduccio"))
        return urlEntorn()+"/2200-publicfrontalinvestigator-pre/biobanc-pre";
    else
        return urlEntorn()+"/2200-publicfrontalinvestigator-pro/biobanc-pro";
}
