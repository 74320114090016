import { Routes, Route } from "react-router-dom";
import React from "react";
import Inici from "../Pages/Inici";


export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Inici />} />
      <Route path="/portalPublic" element={<Inici />} />
      <Route path="/inici" element={<Inici />} />
    </Routes>
  );
}
