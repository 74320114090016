import { useState, useContext } from "react";
import axios from "axios";
import {
  ToastContext,
  tipusToast,
} from "../../Components/ComponentsReusable/Toast/Toast";

import { apimId,investigador,biobanc } from "../constants/personalitzades";

const usePortalPublic = () => {
  const [error, setError] = useState("");
  const investigadorUrl = investigador();
  const biobancUrl = biobanc();
  const apimIdHeader = apimId();

  const { nouToast } = useContext(ToastContext);
  const mostrarToast = (text, tipus) => nouToast(text, tipus);
  const [loading, setLoading] = useState(false);
  const [codiValidat, setCodiValidat] = useState(false);
  const [codiValidatGestorDeBiobanc, setCodiValidatGestorDeBiobanc] =
    useState(false);

  const [popUpError, setPopUpError] = useState(false);
  const [terminated, setTerminated] = useState(false);
  const [errorPopUp, setErrorPopUP] = useState("");

  const validarCodiGestorDeBiobanc = (
    codiBiobanc,
    documnetIdentificativo,
    files
  ) => {
    setLoading(true);
    let formData = new FormData();

    if (files) {
      for (let i = 0; i < files.length; i++) {
        //formData.append("files", files[i]);
        switch (i) {
          case 0:
            formData.append("fileOne", files[i]);
            break;
          case 1:
            formData.append("fileTwo", files[i]);
            break;
          case 2:
            formData.append("fileThree", files[i]);
            break;
          case 3:
            formData.append("fileFour", files[i]);
            break;
          case 4:
            formData.append("fileFive", files[i]);
            break;
          case 5:
            formData.append("fileSix", files[i]);
            break;
          case 6:
            formData.append("fileSeven", files[i]);
            break;
          default:
            break;
        }
      }
    }
    formData.append("dni", documnetIdentificativo);
    formData.append("codi", codiBiobanc);

    axios
      .post(biobancUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "2200-Biob-ClientId": apimIdHeader
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data === "OK") {
          //setCodiValidat(true);
          if (files) {
            mostrarToast(
              "S'ha planificat correctament la seva càrrega. En breu podrà consultar la informació carregada a l'aplicació Biobanc",
              tipusToast.exit
            );
            setCodiValidatGestorDeBiobanc(false);
          } else {
            mostrarToast(
              "El codi s'ha validat i pujat correctament",
              tipusToast.exit
            );
            setCodiValidatGestorDeBiobanc(true);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
        console.log(err);
        if (files) {
          mostrarToast(
            "No s'ha planificat correctament la seva càrrega.",
            tipusToast.error
          );
        } else {
          if (error.response.status === 404) {
            mostrarToast("Error en validar i pujar el codi", tipusToast.error);
          } else if (error.response.status === 500) {
            mostrarToast(
              "S'ha produït un error al processar la seva petició",
              tipusToast.error
            );
          }
        }

        setCodiValidat(false);
      })
      .finally(() => {});
  };

  const validarCodiAndAdjuntarDocuments = (
    codi,
    transformedData,
    arrayFiles
  ) => {
    const formData = new FormData();

    formData.append("codi", codi);

    if (arrayFiles) {
      formData.append("documents", JSON.stringify(transformedData));
      for (let i = 0; i < arrayFiles.length; i++) {
        formData.append("files", arrayFiles[i]);
      }
    }

    setLoading(true);

    axios
      .post(investigadorUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "2200-Biob-ClientId": apimIdHeader
        },
      })
      .then((response) => {
        setLoading(false);
        if (arrayFiles) {
          mostrarToast(
            "S'han carregat els arxius correctament",
            tipusToast.exit
          );
          setTerminated(true);
        } else {
          //mostrarToast("El codi s'ha validat correctament", tipusToast.exit);

          setCodiValidat(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (arrayFiles) {
          //console.error(error.response.data.errors[0].code);

          if (error.response.data.errorCode === 701) {
            console.log("Entraa701");
            setErrorPopUP(error.response.data.message);
            setPopUpError(true);
          }

          mostrarToast(
            "No s'han carregat els arxius correctament",
            tipusToast.error
          );
        } else {
          console.log(error);
          if (error.response.status === 404) {
            mostrarToast("El codi no és correcte", tipusToast.error);
          } else if (error.response.status === 500) {
            mostrarToast(
              "S'ha produït un error al processar la seva petició",
              tipusToast.error
            );
          }
          setError(error);
          setCodiValidat(false);
        }
      })
      .finally(() => {});
  };

  return {
    validarCodiAndAdjuntarDocuments,
    error,
    codiValidat,
    loading,
    popUpError,
    setPopUpError,
    errorPopUp,
    validarCodiGestorDeBiobanc,
    codiValidatGestorDeBiobanc,
    setCodiValidat,
    setCodiValidatGestorDeBiobanc,
    setTerminated,
    terminated,
  };
};

export default usePortalPublic;
