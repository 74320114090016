import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import useConstant from "use-constant";

function useConst(init) {
  // We cannot useMemo, because it is not guranteed to never rerun.
  // https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
  const ref = useRef(null);
  if (ref.current === null) {
    ref.current = init();
  }
  return ref.current;
}

function MyWindowPortal({ title, children, onClose }) {
  const titleEl = useConst(() => document.createElement("title"));
  const containerEl = useConst(() => document.createElement("div"));

  const cache = useConstant(() =>
    createCache({ key: "external", container: containerEl })
  );

  const [isOpened, setOpened] = useState(false);

  useEffect(() => {
    const externalWindow = window.open(
      "",
      "",
      "width=700,height=500,left=200,top=200,scrollbars=on,resizable=on,dependent=on,menubar=off,toolbar=off,location=off"
    );

    // if window.open fails
    if (!externalWindow) {
      onClose();
      return;
    }

    externalWindow.addEventListener("beforeunload", onClose);

    externalWindow.document.head.appendChild(titleEl);
    externalWindow.document.body.appendChild(containerEl);

    setOpened(true);

    return () => {
      externalWindow.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    titleEl.innerText = title;
  }, [title, titleEl]);

  return isOpened
    ? ReactDOM.createPortal(
        <CacheProvider value={cache}> {children}</CacheProvider>,
        containerEl
      )
    : null;
}

export default MyWindowPortal;
