import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormHelperText from "@mui/material/FormHelperText";
import ButtonNormal from "../../ComponentsReusable/Botons/ButtonNormal";
import TexfieldNumber from "../../ComponentsReusable/TextFieldNumber";

function Investigador(props) {
  const { codiValidat, handleCodiValidar, handleChangeMostrarAdjuntos,fullScreen,popUpError,handleClosePopUp,handleSubmitSobreEscribiendo,errorPopUp } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: "15px",
      }}
    >
      <FormHelperText>
        A continuació empleni les dades associades a la seva sol·licitud: ​
      </FormHelperText>
      <TexfieldNumber
        name="Codi"
        nombre="Codi"
        disabled={codiValidat}
        handleActualizarObjeto={handleCodiValidar}
      />
      <div style={{ marginLeft: 145, marginTop: 25 }}>
        <ButtonNormal
          nombre="Validar"
          outlined={true}
          disabled={codiValidat}
          click={handleChangeMostrarAdjuntos}
        />
      </div>
      

      <Dialog
        fullScreen={fullScreen}
        open={popUpError}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Vols sobreescriure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{errorPopUp}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonNormal click={handleClosePopUp} nombre="NO" outlined={true} />

          <ButtonNormal
            click={handleSubmitSobreEscribiendo}
            nombre="SI"
            outlined={false}
          />
        </DialogActions>
      </Dialog>
      
    </div>
  );
}
export default Investigador;
