import { Container } from "@mui/system";
import "../../App.css";
import React from "react";

import logo from "../Resources/logo.png";


function Header() {
 

  return (
    <div className="App-header">
      <Container maxWidth="100%">
        <div className="Header-branding">
          <img src={logo} className="App-logo" alt="logo" />
          <div className="Header-buttons">
          

        </div>
        </div>
      </Container>
      </div>
  );
}

export default Header;
