import React from "react";
import UploadFile from "./UploadFile";
import MyWindowPortal from "./MyWindowPortal";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";

const styleUploadFiles = {
  backgroundColor: "#D3D3D3",
  borderWidth: "2px",
  borderRadius: "2px",
  marginBottom: 1,
};

function UploadSamplesFile(props) {
  const {
    setOpen,
    setTerminated,
    files,
    setFiles,
    handleChangeMostrarAdjuntos,
  } = props;

  const onDrop = (docs) => {
    setFiles((actualDocs) => [...actualDocs, ...docs]);
  };

  const onDeleteFile = (file) => {
    setFiles((actualDocs) => {
      return actualDocs.filter((doc) => {
        return doc.name !== file.name;
      });
    });
  };

  const onSendFiles = () => {
    setOpen(false);
    setTerminated(true);
    handleChangeMostrarAdjuntos();
  };

  return (
    <MyWindowPortal
      title={"Càrrega de mostres"}
      onClose={() => {
        setOpen(false);
      }}
    >
      <UploadFile onDrop={onDrop} />

      {files.map((file) => (
        <div style={styleUploadFiles} key={file.name}>
          <Stack direction="row">
            <Stack direction="row" spacing={2}>
              <ArrowRightIcon fontSize="small" />
              <Typography variant="body1">{file.name}</Typography>
            </Stack>
            <Stack marginLeft="auto">
              <CloseIcon
                sx={{ color: "red" }}
                onClick={() => onDeleteFile(file)}
              ></CloseIcon>
            </Stack>
          </Stack>
        </div>
      ))}

      {files.length > 0 && (
        <Button
          size={"small"}
          variant="contained"
          sx={{
            marginTop: 1,
            color: "white",
            backgroundColor: "#900000",
            justifyContent: "center",
            ":hover": { backgroundColor: "#900000" },
          }}
          onClick={() => onSendFiles()}
        >
          Enviar
        </Button>
      )}
    </MyWindowPortal>
  );
}

export default UploadSamplesFile;
