import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import ButtonNormal from "../../ComponentsReusable/Botons/ButtonNormal";
import Textfield from "../../ComponentsReusable/Texfield";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UploadSamplesFile from "./UploadSamplesFile";
import { Button } from "@mui/material";

function GestorDeBiobancs(props) {
  const {
    codiValidatGestorDeBiobanc,
    validarDocumentIdentificativo,
    validarCampoCodi,
    handleCodiValidar,
    handleChangeMostrarAdjuntos,
    CustomTooltip,
    carregaButtonClick,
    isOpen,
    setOpen,
    setTerminated,
    filesMostres,
    setFilesMostres,
  } = props;
  return (
    <>
      <div className="enLinea">
        <div style={{ marginTop: 25 }}>
          <FormHelperText>Introdueixi el codi de Biobanc:​</FormHelperText>
          <Textfield
            name="codiBiobanc"
            nombre="Codi Biobanc"
            disabled={codiValidatGestorDeBiobanc}
            handleActualizarObjeto={handleCodiValidar}
            error={validarCampoCodi}
            mensajeError={"El valor introduït no és vàlid"}
          />
          <div>
            <FormHelperText>
              Introdueixi el seu document d’identitat:​
            </FormHelperText>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Textfield
                name="IdDocument"
                nombre="Document identificatiu"
                disabled={codiValidatGestorDeBiobanc}
                error={validarDocumentIdentificativo}
                handleActualizarObjeto={handleCodiValidar}
                mensajeError={"El valor introduït no és un DNI o NIE vàlid"}
              />
              <CustomTooltip
                title="Intrudeixi el NIE o DNI de la persona que realitza les càrregues"
                placement="right-start"
              >
                <InfoOutlinedIcon />
              </CustomTooltip>
            </div>
          </div>
          <div style={{ marginLeft: 145, marginTop: 25 }}>
            <ButtonNormal
              nombre="Validar"
              outlined={true}
              disabled={codiValidatGestorDeBiobanc}
              click={handleChangeMostrarAdjuntos}
            />
          </div>
        </div>
      </div>
      {codiValidatGestorDeBiobanc === true && (
        <>
          {" "}
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              marginTop: 2,
              width: 222,
              height: 50,
            }}
            onClick={carregaButtonClick}
          >
            Càrregues de mostres
          </Button>
          {isOpen && (
            <UploadSamplesFile
              setOpen={setOpen}
              setTerminated={setTerminated}
              files={filesMostres}
              setFiles={setFilesMostres}
              handleChangeMostrarAdjuntos={handleChangeMostrarAdjuntos}
            />
          )}
        </>
      )}
    </>
  );
}
export default GestorDeBiobancs;
