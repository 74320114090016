import React from 'react'
import TextField from '@mui/material/TextField';


function TexfieldNumber(props) {
    const {name,nombre,handleActualizarObjeto,value,disabled}= props
    
    return (
        
      <TextField
      id="outlined-number"
      disabled={disabled}
      name={name}
      label={nombre}
      onChange={handleActualizarObjeto}
      step="0.1"
      type="number"
      inputProps={{
        min: 0
      }}
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      sx={{ marginTop:1, minWidth: 50 }}
    />
    
    )

    
}
export default TexfieldNumber;