import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';




function Selects(props) {
    const { handleChange, value, name, elements, nameLabel } = props

    return (

            <div>
            <Stack spacing={3}>
            <FormControl sx={{ marginLeft:2, minWidth: 200 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">{nameLabel}</InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"

                        onChange={handleChange}
                        value={value?'':value}
                        autoWidth
                        label={nameLabel}
                        name={name}
                    >

                        {elements.map((elements) => {
                            return <MenuItem disabled={elements.disabled} key={elements.id} value={elements.id} name={elements.value}>
                                {elements.value}
                            </MenuItem>
                        })}
                    </Select>

                </FormControl>
            </Stack>
        </div>
        



    );
}
export default Selects;


