import React from "react";
import "../../../../App.css";
import Button from "@mui/material/Button";

function ButtonNormal(props) {
  const { click, nombre, outlined,disabled } = props;



  return (
    <>
      {outlined ? <Button
        variant="outlined"
        disabled={disabled}
        onClick={click}
        sx={{textTransform: "none", marginRight:2}}
      // startIcon={<img src={require([src]).default} />}
      >
        {nombre}
      </Button> :
      
      <Button
        variant="contained"
        onClick={click}
        disabled={disabled}
        sx={{textTransform: "none"}}
      // startIcon={<img src={require([src]).default} />}
      >
        {nombre}
      </Button>}

    </>
  );
}
export default ButtonNormal;
