import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
// import { document } from "../NewRequestObjects/Objects";
import {
  CancelIconStyle,
  LabelStyle,
  UploadFileButtonStyle,
} from "./UploadFileButtonStyles";

function UploadFileButton(props) {
  const { label, id, uploadFile, deleteFile } = props;
  const [file, setFile] = useState();
  const [visible, setVisible] = useState(false);
  // const [doc, setDoc] = useState(document);

  // const setDocumentValues = (e) => {
  //   var name = e.target.files[0].name;
  //   setDoc((prevState) => {
  //     prevState["tipusDocument"] = label;
  //     prevState["nomDocument"] = name;
  //     return { ...prevState };
  //   });
  // };


  const onFileSelected = (e) => {
    var extractedFile = e.target.files[0];
    setFile(extractedFile);
    // setDocumentValues(e);
    uploadFile(extractedFile); // TODO, HABÍA UN DOC COMO PRIMER PARÁMETROS
    setVisible(true);
  };

  const onDeleteFile = (e) => {
    deleteFile(file);
    setFile(null);
    setVisible(false);
  };

  return (
    <div>
      <TextField
        value={file ? file.name : "Seleccionar"}
        label={label}
        fullWidth
        InputProps={{
          endAdornment: (
            <>
              {visible && (
                <CancelOutlinedIcon
                  id={id}
                  sx={CancelIconStyle}
                  onClick={(e) => onDeleteFile(e)}
                />
              )}
              <input
                style={{ display: "none" }}
                id={id}
                type="file"
                onChange={(e) => {
                  onFileSelected(e);
                  e.target.value = null;
                }}
              />
              <label style={LabelStyle} htmlFor={id}>
                <Button
                  variant="outlined"
                  component="span"
                  size="large"
                  sx={UploadFileButtonStyle}
                >
                  Carregar document
                </Button>
              </label>
            </>
          ),
        }}
      ></TextField>

{/* <TextField

value={file ? file.name : "Seleccionar"}
>
      <Button variant="contained" component="label" color="primary" >
        {" "}
        Upload a file
        <input type="file"  />
      </Button>
    </TextField> */}
    </div>
  );
}

export default UploadFileButton;
