import '../../App.css';
import React from "react";
import BoxPortalPublic from "../Components/ComponentesBoxPrincipal/BoxPrincipal/BoxPrincipal"


function Inici() {
  

  return (
    <div className="App">

      <BoxPortalPublic></BoxPortalPublic>

    </div>

  );
}

export default Inici;
